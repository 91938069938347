import React, { Component } from "react"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import { Link } from "gatsby"
import SocialMedia from "../components/social_media-header"
import {Helmet} from "react-helmet"


export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }

  render() {

    return (
      <Layout>
        <Helmet>
          <title>CueForGood: Magento Development, Website Design, Search Engine Optimization and Conversion Rate Optimization for eCommerce Stores | Digital Design and Marketing Agency</title>
          <meta name="description"
            content="CueForGood has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
          <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no" />
          <link rel="canonical" href={this.state.url} />
          <meta property="og:site_name" content="CueForGood" />
          <meta property="fb:app_id" content="289086684439915" />
          <meta property="og:url" content={this.state.url} />
          <meta property="og:title"
            content="CueForGood: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing." />
          <meta property="og:description"
            content="CueForGood has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
          <meta property="og:image" content="https://www.CueForGood.com/images/cb.jpg" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description"
            content="CueForGood has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
          <meta name="twitter:title"
            content="CueForGood: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing." />
          <meta name="twitter:site" content="@CueForGood" />
          <meta name="twitter:image" content="https://www.CueForGood.com/images/cb.jpg" />
        </Helmet>
        <section className="ser_sec_row error-page sitemap-page thankyou-page">
          <div className="container">
            <div className="main-heading">
              <h6>IT'S A 404 ERROR</h6>
              <h2 className="heading_main">The page you're looking for could not be found.</h2>

              <p>Now you might want to: </p>
              <ul className="outerul">
                <li><Link to="/" >Go back to the homepage</Link> </li>
                <li><Link to="/services" >Find out about our services</Link> </li>
                <li><Link to="/about" >Get to know us a little better</Link> </li>
                <li><Link to="/career" >Look at job opportunities at CueForGood</Link> </li>
                <li><a href="https://www.cueforgood.com/blog/" >Go to our Blog</a> </li>
                <li><a href="https://store.cueblocks.com/" >Check out our Magento Extensions Store</a> </li>
                <li><Link to="/contact" >Get in touch with us</Link> </li>
              </ul>
            </div>

          </div>
        </section>
      
      </Layout>
    )

  }
}

